import React from "react"
import { Link, graphql } from "gatsby"
import { getSrc, GatsbyImage } from "gatsby-plugin-image";
import parse from "html-react-parser"
import * as containerStyles from '~/components/pages/blog/blog.module.scss'

import Seo from "../components/seo"
import ShareBtn from '~/components/modules/snsshare'
import Arrow from "~/images/common_icon_arrow_r.svg";
import BacktoList from "~/images/common_icon_backlist.svg";
import RelatedPost from '~/components/pages/blog/relatedpost'
import TagList from "../components/modules/tagList";

const BlogPostTemplate = ({ data: { post } }) => {
  const featuredImage = {
    image: post.featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData,
    alt: post.featuredImage?.node?.alt || ``,
  }
  const featuredImagePath = getSrc(featuredImage.image)
  const category = {
    name: post.categories?.nodes[0]?.name,
    uri: post.categories?.nodes[0]?.uri,
    slug: post.categories?.nodes[0]?.slug,
  }
  const tags = post.tags?.nodes
  const categories = post.categories?.nodes
  const author = {
    name: post.author?.node?.name,
  }

  return (
    <>
      <Seo title={post.title} pageUrl={'blog/' + post.id} ogp={featuredImage ? 'https://shpree.jp' + featuredImagePath : null}/>

      <div className="blog">
        <nav className="contentComponent">
          <ul className="breadcrumbComponent mincho">
            <li><Link to="/" itemProp="url">TOP</Link> <span><Arrow /></span></li>
            <li><Link to="/blog/" itemProp="url">BLOG</Link> <span><Arrow /></span></li>
            <li>{post.title}</li>
          </ul>
        </nav>
        <article
          className={containerStyles.article}
          itemScope
          itemType="http://schema.org/Article"
        >
          <header className={containerStyles.articleHeader + " contentSubComponent"}>
            <div className="mincho">
              <p className={containerStyles.articleCategory + " mincho"}><Link to={`/category/${category.slug}`}>{category.name}</Link></p>
              <h1 itemProp="headline"  className={containerStyles.articleTitle + " heading is-primary"}>
                <span itemProp="headline">{parse(post.title)}</span>
              </h1>
              <small className={containerStyles.articleDate + " mincho"}>{post.date}</small>
            </div>
            <p className={containerStyles.articleAuthor}>STAFF / <span>{author.name}</span></p>
          </header>

          <hr className="borderLine" />

          {!!post.content && (
            <section itemProp="articleBody"  className="contentSubComponent">
              {featuredImage?.image && (
                <div className={containerStyles.thmbnail}>
                <GatsbyImage
                  image={featuredImage.image}
                  alt={featuredImage.alt} />
                </div>
              )}
              <div
                className={containerStyles.articleBody}
                dangerouslySetInnerHTML={{
                  __html: post.content,
                }}
              />
            </section>
          )}

          <footer className={containerStyles.articleFooter}>
            <div className="contentSubComponent">
              {categories.length > 0 && (
                <dl className="tagListComponent mincho">
                  <dt className="heading">CATEGORY</dt>
                  <TagList type={'category'} tags={categories} />
                </dl>
              )}
              {tags.length > 0 && (
                <dl className="tagListComponent mincho">
                  <dt className="heading">TAG</dt>
                  <TagList type={'tag'} tags={tags} />
                </dl>
              )}
              <div className="shareIcons is-bk">
                <dl className={containerStyles.articleShare}>
                  <dt className="mincho heading">Share</dt>
                  <ShareBtn
                    title={post.title}
                    link={`https://shpree.jp/blog/${post.id}/`}
                  />
                </dl>
              </div>
              <Link className={containerStyles.articleBackLink} to="/blog/" ><BacktoList /></Link>
            </div>
          </footer>
        </article>

        <section className={containerStyles.relatedPostList}>
          <div className="borderLine">
            <h2 className="contentSubComponent heading with-english is-secondary"><span>関連記事</span>Recommend</h2>
          </div>
          <RelatedPost slug={category.slug} />
        </section>

      </div>

    </>
  );
}

export default BlogPostTemplate

export const pageQuery = graphql`query BlogPostById($id: String!) {
  post: wpPost(id: {eq: $id}) {
    id
    excerpt
    content
    title
    slug
    tags {
      nodes {
        uri
        name
        slug
      }
    }
    categories {
      nodes {
        name
        uri
        slug
      }
    }
    author {
      node {
        name
      }
    }
    date(formatString: "YYYY.MM.DD")
    featuredImage {
      node {
        altText
        localFile {
          childImageSharp {
            gatsbyImageData(quality: 100, placeholder: BLURRED, layout: FULL_WIDTH)
          }
        }
      }
    }
  }
}
`
