import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import { GatsbyImage, StaticImage } from "gatsby-plugin-image"
import parse from "html-react-parser"
import * as containerStyles from '~/components/pages/blog/blog.module.scss'

const RelatedPost = ({ slug }) => {
  const { allWpPost } = useStaticQuery(
    graphql`
      query {
        allWpPost {
          edges {
            node {
              date(formatString: "YYYY.MM.DD")
              title
              id
              uri
              categories {
                nodes {
                  name
                  slug
                }
              }
              featuredImage {
                node {
                  altText
                  localFile {
                    childImageSharp {
                      gatsbyImageData(quality: 100, placeholder: BLURRED, layout: FULL_WIDTH)
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  )

  const filterArticle = allWpPost.edges.filter((element, index, array) => [slug].includes(element.node.categories.nodes[0].slug))

  return (
    <div className={containerStyles.articleRelatedList}>
      {filterArticle.length >= 1 ?
        filterArticle.slice(0, 3).map(({ node }) => {
          const title = node.title
          const featuredImage = {
            image: node.featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData,
            alt: node.featuredImage?.node?.alt || ``,
          }
          const category = {
            name: node.categories?.nodes[0]?.name,
            uri: node.categories?.nodes[0]?.uri,
          }

          return (
            <article
              className={containerStyles.articleItem}
              itemScope
              itemType="http://schema.org/Article"
              key={node.id}
            >
              <Link to={`/blog/${node.id}`} itemProp="url" className="contentSubComponent">
                <div className={containerStyles.thmbnail}>
                  {featuredImage?.image ? (
                    <GatsbyImage
                      image={featuredImage.image}
                      alt={featuredImage.alt} />
                  ) : <StaticImage
                    src="../images/top_mv_pc.jpg"
                    alt=""
                    placeholder="blurred"
                    layout="fullWidth" />}
                </div>
                <section className={containerStyles.articleDetail}>
                  <header className="mincho">
                    {category?.name && (
                      <span className={containerStyles.articleCategory}>
                        {category.name}
                      </span>
                    )}
                    <h2 className={containerStyles.articleTitle + " heading is-secondary"}>
                      <span itemProp="headline">{parse(title)}</span>
                    </h2>
                  </header>
                  <small className={containerStyles.articleDate + " mincho"}>{node.date}</small>
                  <div className={containerStyles.articleExpert} itemProp="description" dangerouslySetInnerHTML={{ __html: node.excerpt }} />
                </section>
              </Link>
            </article>
          )
        }
      ) : <p>関連記事はありません</p>}
    </div>
  )
}

export default RelatedPost
