import React from 'react'
import { FacebookShareButton, TwitterShareButton, LineShareButton } from "react-share";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTwitter, faInstagram, faFacebookF } from '@fortawesome/free-brands-svg-icons'
import LineIcon from "~/images/common_icon_line.svg"

export default function SNSShare({title, link}) {
  return(
    <>
      <dd className="shareIconsTwitter">
        <TwitterShareButton title={title} url={link} via="shpreejp">
          <FontAwesomeIcon icon={faTwitter} />
        </TwitterShareButton>
      </dd>
      <dd className="shareIconsFacebook">
        <FacebookShareButton url={link}>
          <FontAwesomeIcon icon={faFacebookF} />
        </FacebookShareButton>
      </dd>
      <dd className="shareIconsLine">
        <LineShareButton url={link}>
          <LineIcon />
        </LineShareButton>
      </dd>
    </>
  )
}
